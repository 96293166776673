<template>
  <b-sidebar
    id="add-new-sidebar"
    :visible="isUpdateSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    width="700px"
    right
    @change="(val) => $emit('update:is-update-sidebar-active', val)"
  ><template #default="{ hide }">
    <!-- Header -->
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <h5 class="mb-0">
        show Subscribe Details
      </h5>

      <feather-icon
        class="ml-1 cursor-pointer"
        icon="XIcon"
        size="16"
        @click="hide"
      />
    </div>

    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- Skill Name -->
        <validation-provider
          #default="validationContext"
          name="name"
          rules="required"
        >
          <b-form-group
            label="Customer Name"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="skill.user.username"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Skill Slug -->
        <validation-provider
          #default="validationContext"
          name="type"
          rules="required"
        >
          <b-form-group
            label="Customer email"
            label-for="type"
          >
            <b-form-input
              id="slug"
              v-model="skill.user.email"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Skill Type -->
        <validation-provider
          #default="validationContext"
          name="price"
          rules="required"
        >
          <b-form-group
            label="Plan Price"
            label-for="price"
          >
            <b-form-input
              id="price"
              v-model="skill.amountTotal"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="plan"
          rules="required"
        >
          <b-form-group
            label="Plan name"
            label-for="price"
          >
            <b-form-input
              id="price"
              v-model="skill.plan.name"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="price"
          rules="required"
        >
          <b-form-group
            label="Payment Method"
            label-for="price"
          >
            <b-form-input
              id="price"
              v-model="skill.paymentMethod"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="price"
          rules="required"
        >
          <b-form-group
            label="Payment Intent"
            label-for="price"
          >
            <b-form-input
              id="price"
              v-model="skill.paymentIntent"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="price"
          rules="required"
        >
          <b-form-group
            label="Session Id"
            label-for="price"
          >
            <b-form-input
              id="price"
              v-model="skill.sessionId"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="price"
          rules="required"
        >
          <b-form-group
            label="Start Date"
            label-for="price"
          >
            <b-form-input
              id="price"
              v-model="skill.startDate"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="price"
          rules="required"
        >
          <b-form-group
            label="End Date"
            label-for="price"
          >
            <b-form-input
              id="price"
              v-model="skill.endDate"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Close
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </template></b-sidebar>
</template>
<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import useSkillsList from './useContactList'

import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    isUpdateSidebarActive: {
      required: true,
      type: Boolean,
    },
    skill: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      getCountriesCurrency: 'country/getCountriesCurrency',

    }),
  },
  created() {
    this.fetchCountries()
  },
  methods: {
    ...mapActions({
      fetchCountries: 'country/retreiveCountries',
    }),
  },
  setup(props, { emit }) {
    const { skillStatuses, updateSkill } = useSkillsList()

    const { refFormObserver, getValidationState, resetForm } = formValidation()

    const onSubmit = async () => {
      const response = await updateSkill(props.skill)
      if (typeof response !== 'undefined') {
        emit('refetch-data')
        emit('update:is-update-sidebar-active', false)
      }
    }

    return {
      refFormObserver,
      getValidationState,
      skillStatuses,
      resetForm,
      onSubmit,
    }
  },
}
</script>
